export default{
  isStudent: {
    name: "isStudent",
    component: "filterSelect",
    label: "Professional Type",
    queryKey: "is_student",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  },
  candidateType: {
    name: "candidateType",
    component: "filterSelect",
    placeholder: "All",
    label: "Student Type",
    queryKey: "candidate_type_id__in",
    dependsOn: [],
    dependentFilter: [],
    secondLevelDependentDisable: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  courseTitle: {
    name: "courseTitle",
    component: "filterSelect",
    label: "Course/Programme",
    queryKey: "course_title_id__in",
    dependsOn: [],
    dependentFilter: [],
    secondLevelDependentDisable: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  fullPartTime: {
    name: "fullPartTime",
    component: "filterSelect",
    label: "Study Mode",
    queryKey: "candidate_training_courses___full_part_time",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  isSponsored: {
    name: "isSponsored",
    component: "filterSelect",
    label: "Sponsorship",
    queryKey: "is_sponsored",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  },
  eligible: {
    name: "eligible",
    component: "filterSelect",
    label: "Traffic Lights",
    queryKey: "candidate_eligibility",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  },
  pqrCountries: {
    name: "pqrCountries",
    component: "filterSelect",
    label: "Licensing Country",
    queryKey: "pqr_country_id",
    groupFilterName: "Eligibility",
    dependsOn: [],
    secondLevelDependentClear: ['pqrAHPSpeciality','workingLevel'],
    // secondLevelDependentDisable: ["candidateType"],
    dependentFilter: ["pqrAHPSpeciality", "workingLevel", "pqrHeader"],
    apiDependentFilter: ["pqrHeader", "pqrAHPSpeciality", "workingLevel"], //pqrLicensingAuthority is dependent on pqrCountries
    taggable: false,
    multiple: false
  },
  pqrHeader: {
    name: "pqrHeader",
    component: "filterSelect",
    label: "Licensure Region",
    queryKey: "pqr_header_id",
    groupFilterName: "Eligibility",
    dependsOn: [],
    secondLevelDependentClear: ['workingLevel'],
    secondLevelDependentDisable: ["pqrCountries"],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    autoPopulate: true,
  },
  workingLevel: {
    name: "workingLevel",
    component: "filterSelect",
    label: "Level",
    queryKey: "pqr_working_level_id__in",
    groupFilterName: "Eligibility",
    dependsOn: ["candidateType"],
    secondLevelDependentDisable: ["pqrCountries"],
    selectDependentDisable: ["candidateLevel"],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    hideCondition:{
      conditionalField: "candidateType",
      value: [3],
      defaultShow: true,
    }
  },
  candidateLevel: {
    name: "candidateLevel",
    component: "filterSelect",
    label: "Working As (Level)",
    queryKey: "level_id__in",
    dependsOn: ["candidateType"],
    secondLevelDependentDisable: ["candidateType"],
    taggable: false,
    multiple: true,
  },
  specialitySearch: {
    name: "specialitySearch",
    component: "accordionSelect",
    label: "Speciality/Sub-Speciality",
    queryKey: "speciality_references",
    dependsOn: [],
    dependentFilter: [],
    secondLevelDependentDisable: ['candidateType'],
    taggable: false,
    multiple: true,
    clearable: false,
    filterDynamicSearchAction: "genericSpecialitySearch",
    dependentQuerys: ["candidateType"],
  },
  licence: {
    name: "licence",
    component: "filterSelect",
    label: "Regional License",
    queryKey: "local_license__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  // active: {
  //   name: "active",
  //   component: "filterSelect",
  //   label: "Active",
  //   queryKey: "available_during_covid__in__and",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: true,
  // },
  // deleted: {
  //   name: "deleted",
  //   component: "filterSelect",
  //   label: "Deleted",
  //   queryKey: "deleted",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  availabilityType: {
    name: "availabilityType",
    component: "filterSelect",
    label: "Contract",
    queryKey: "job_type",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  qualificationClassificationCountries: {
    name: "qualificationClassificationCountries",
    component: "filterSelect",
    label: "Qualification Country",
    queryKey: "qualification_country_id",
    groupFilterName: "Qualification Level",
    dependsOn: [],
    secondLevelDependentDisable: ["candidateType"],
    dependentFilter: [],
    apiDependentFilter: ["qualificationLevel"], //pqrHeader is dependent on pqrCountries
    taggable: false,
    multiple: false,
  },
  qualificationLevel: {
    name: "qualificationLevel",
    component: "filterSelect",
    label: "Qualification Level",
    queryKey: "qualification_level_id",
    groupFilterName: "Qualification Level",
    dependsOn: [],
    secondLevelDependentDisable: ["qualificationClassificationCountries"],
    dependentFilter: [],
    taggable: false,
    multiple: false,
  },
  experience:{
    name: "experience",
    component: "filterDateInput",
    label: "Year Awarded",
    queryKey: "year_awarded",
    inputFields:["year"],
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  currentCountry: {
    name: "currentCountry",
    component: "filterSelect",
    label: "Current Home Location",
    queryKey: "country_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  gender: {
    name: "gender",
    component: "filterSelect",
    label: "Gender",
    queryKey: "gender_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  birthPlaces: {
    name: "birthPlaces",
    component: "filterSelect",
    label: "Birth Place",
    queryKey: "birth_place_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  nationalityInfo: {
    name: "nationalityInfo",
    component: "filterSelect",
    label: "Nationality",
    queryKey: "nationality_ids__overlap",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  qualificationCountries: {
    name: "qualificationCountries",
    component: "filterSelect",
    label: "Qualification Country",
    queryKey: "candidate_qualifications___main=true&candidate_qualifications___country_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  locationRegion: {
    name: "locationRegion",
    component: "filterCheckBox",
    label: "Preferred Region",
    queryKey: "region_ids__overlap",
    dependsOn: [],
    groupFilterName: "Preferred Region/Country",
    dependentFilter: ["preferredLocations"],
    apiDependentFilter: ["preferredLocations"], 
    taggable: false,
    multiple: true,
    clearable: true,
    showCount: true,
  },
  preferredLocations: {
    name: "preferredLocations",
    component: "filterCheckBox",
    label: "Preferred Country",
    queryKey: "preferred_country_ids__overlap",
    dependsOn: ["locationRegion"],
    clearOnlyDepends: true,
    groupFilterName: "Preferred Region/Country",
    dependentFilter: [],
    secondLevelDependentDisable: ["locationRegion"],
    taggable: false,
    multiple: true,
    clearable: true,
    showCount: true,
  },
  locationLanguage: {
    name: "locationLanguage",
    component: "filterSelect",
    label: "Language",
    queryKey: "language_ids__overlap",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  ageLimit: {
    name: "ageLimit",
    component: "filterRange",
    label: "Age limit",
    queryKey: "age__lte",
    dependsOn: [],
    dependentFilter: [],
    selected: [20], //default value
    min: 20,
    max: 70,
    type: "range",
  },
  available: {
    name: "available",
    component: "filterSelect",
    label: "Actively Seeking Employment",
    queryKey: "available__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
};