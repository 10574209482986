export default {
  courseType: {
    name: "courseType",
    component: "filterSelect",
    label: "Course Type",
    queryKey: "course_test_type_id",
    dependsOn: [],
    secondLevelDependentClear: ['orgDepartment', 'healthSector'],
    dependentFilter: ["orgDepartment", "healthSector"],
    apiDependentFilter: [],
    taggable: false,
    multiple: false,
  },
  healthSector: {
    name: "healthSector",
    component: "filterSelect",
    label: "Healthcare Sector",
    queryKey: "healthcare_sector",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    autoPopulate: false,
  },
  locationLanguage: {
    name: "locationLanguage",
    component: "filterSelect",
    label: "Language",
    queryKey: "language_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  studyMode: {
    name: "studyMode",
    component: "filterSelect",
    label: "Delivery Mode",
    queryKey: "study_mode__in",
    dependsOn: [],
    secondLevelDependentDisable: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
};
