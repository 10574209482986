export default {
  locationCountryInfo: {
    name: "locationCountryInfo",
    component: "filterSelect",
    label: "Licensing Country",
    queryKey: "licensing_country_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: false,
    clearable: false,
    dependentFilter: [
      "licensingAuthority",
      "licensingLevel",
      "speciality",
      "subSpeciality",
      "specialInterest",
      "skill",
      "countryIncluded",
      "gender"
    ],
    display: false
  },
  licensingAuthority: {
    name: "licensingAuthority",
    component: "filterSelect",
    label: "Licensing Authority",
    queryKey: "license_auth_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    display: false
  },
  licensingLevel: {
    name: "licensingLevel",
    component: "filterSelect",
    label: "Licensing Level",
    queryKey: "licensing_level_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    display: false
  },
  candidateType: {
    name: "candidateType",
    component: "filterSelect",
    label: "Candidate Type",
    queryKey: "candidate_type_id__in",
    options: [],
    selected: [],
    dependentFilter: [
      "recruitingCandidateLevel",
      "speciality",
      "subSpeciality",
      "specialInterest"
    ],
    taggable: false,
    multiple: false,
    clearable: false
  },
  recruitingCandidateLevel: {
    name: "recruitingCandidateLevel",
    component: "filterSelect",
    label: "Candidate Level",
    queryKey: "level_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  speciality: {
    name: "speciality",
    component: "filterSelect",
    label: "Speciality",
    queryKey: "speciality_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    dependentFilter: ["subSpeciality", "specialInterest", "skill"]
  },
  subSpeciality: {
    name: "subSpeciality",
    component: "filterSelect",
    label: "Sub Speciality",
    queryKey: "sub_speciality_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    disabled: true
  },
  retiredClinician: {
    name: "retiredClinician",
    component: "filterSelect",
    label: "Retired Clinician",
    queryKey: "retired_clinician__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: true
  },
  volunteer: {
    name: "volunteer",
    component: "filterSelect",
    label: "Volunteer (Unpaid)",
    queryKey: "volunteer__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: true
  },
  licence: {
    name: "licence",
    component: "filterSelect",
    label: "Current license",
    queryKey: "local_license__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: true
  },
  active: {
    name: "active",
    component: "filterSelect",
    label: "Active",
    queryKey: "available_during_covid__in__and",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: true,
    display: true
  },
  deleted: {
    name: "deleted",
    component: "filterSelect",
    label: "Deleted",
    queryKey: "deleted",
    options: [],
    selected: [],
    taggable: false,
    multiple: false,
    clearable: false,
    display: true
  },
  availabilityType: {
    name: "availabilityType",
    component: "filterSelect",
    label: "Availability",
    queryKey: "job_type",
    options: [],
    selected: [],
    taggable: false,
    multiple: false,
    clearable: true
  },
  contractType: {
    name: "contractType",
    component: "filterSelect",
    label: "Contract",
    queryKey: "job_time",
    options: [],
    selected: [],
    taggable: false,
    multiple: false,
    clearable: true
  },
  locations: {
    name: "locations",
    component: "filterSelect",
    label: "Current Home Location",
    queryKey: "location_id",
    options: [],
    selected: [],
    taggable: false,
    multiple: false,
    clearable: true
  },
  preferredLocations: {
    name: "preferredLocations",
    component: "filterSelect",
    label: "Preferred Location",
    queryKey: "preferred_location_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: true
  },
  countryIncluded: {
    name: "countryIncluded",
    component: "filterSelect",
    label: "Nationality",
    queryKey: "nationality_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  gender: {
    name: "gender",
    component: "filterSelect",
    label: "Gender",
    queryKey: "gender_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true
  },
  ageLimit: {
    name: "ageLimit",
    component: "filterRange",
    label: "Age limit",
    queryKey: "age__lte",
    options: [],
    selected: [20], //default value
    min: 20,
    max: 70
  },
  available: {
    name: "available",
    component: "filterSelect",
    label: "Actively Seeking Employment",
    queryKey: "available__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    clearable: true
  },
  specialInterest: {
    name: "specialInterest",
    component: "filterSelect",
    label: "Special Interest",
    queryKey: "special_interest_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    display: false
  },
  skill: {
    name: "skill",
    component: "filterSelect",
    label: "Skill",
    queryKey: "skill_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    display: false
  },
  qualification: {
    name: "qualification",
    component: "filterSelect",
    label: "Qualification",
    queryKey: "main_qualification_id__in",
    options: [],
    selected: [],
    taggable: false,
    multiple: true,
    display: false
  },
  isStudent: {
    name: "isStudent",
    component: "filterSelect",
    label: "Professional Type",
    queryKey: "is_student",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  },
};
