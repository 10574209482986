export default {
  studentType: {
    name: "studentType",
    component: "filterSelect",
    placeholder: "All",
    label: "Student Type",
    queryKey: "candidate_type_ids__overlap",
    dependsOn: [],
    dependentFilter: ["courseLevel"],
    secondLevelDependentDisable: [],
    taggable: false,
    multiple: false,
    clearable: false,
    secondLevelDependentClear: ['courseLevel'],
    apiDependentFilter: ["courseLevel"]
  },
  courseType: {
    name: "courseType",
    component: "filterSelect",
    label: "Course Type",
    queryKey: "course_test_type_id",
    dependsOn: [],
    secondLevelDependentClear: ['orgDepartment', 'healthSector'],
    dependentFilter: ["orgDepartment", "healthSector"],
    apiDependentFilter: [],
    taggable: false,
    multiple: false,
  },
  orgDepartment: {
    name: "orgDepartment",
    component: "filterSelect",
    label: "Healthcare Sector/Department",
    queryKey: "org_dept_id",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    autoPopulate: false,
    secondLevelDependentDisable: ["courseType"],
    hideCondition: {
      conditionalField: "courseType",
      value: [1],
      defaultShow: false,
    }
  },
  healthSector: {
    name: "healthSector",
    component: "filterSelect",
    label: "Healthcare Sector/Department",
    queryKey: "healthcare_sector",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    autoPopulate: false,
    hideCondition: {
      conditionalField: "courseType",
      value: [2, 3],
      defaultShow: true,
    }
  },
  courseLevel: {
    name: "courseLevel",
    component: "filterSelect",
    label: "Course Level",
    queryKey: "course_level_id",
    dependsOn: [],
    secondLevelDependentDisable: ["studentType"],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  studyMode: {
    name: "studyMode",
    component: "filterSelect",
    label: "Study Mode",
    queryKey: "study_mode__in",
    dependsOn: [],
    secondLevelDependentDisable: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  locationLanguage: {
    name: "locationLanguage",
    component: "filterSelect",
    label: "Language Delivery",
    queryKey: "language_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  cpdCourse: {
    name: "cpdCourse",
    component: "filterSelect",
    label: "CPD Course",
    queryKey: "cpd_points__gte___cpd_points__lte",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  }
};
