export default {
  organisation: {
    name: "organisation",
    component: "filterSelect",
    label: "Organisation",
    placeholder: "All",
    queryKey: "organisation_id",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
  },
  candidateType: {
    name: "candidateType",
    component: "filterSelect",
    placeholder: "All",
    label: "Candidate Type",
    queryKey: "candidate_type_id__in",
    dependsOn: [],
    dependentFilter: ["candidateLevel", "specialitySearch", 
    // 'pqrAHPSpeciality', 
    // 'workingLevel', 
    // 'pqrHeader', 
    // 'pqrCountries', 
    "qualificationClassificationCountries",],
    secondLevelDependentDisable: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  pqrCountries: {
    name: "pqrCountries",
    component: "filterSelect",
    label: "Licensing Country",
    queryKey: "pqr_country_id",
    groupFilterName: "Eligibility",
    dependsOn: [],
    // secondLevelDependentDisable: ["candidateType"],
    secondLevelDependentClear: ['pqrAHPSpeciality', 'workingLevel'],
    selectDependentDisable: ["candidateLevel"],
    dependentFilter: ["pqrAHPSpeciality", "workingLevel", "pqrHeader"],
    apiDependentFilter: ["pqrHeader", "pqrAHPSpeciality", "workingLevel"], //pqrLicensingAuthority is dependent on pqrCountries
    taggable: false,
    multiple: false
  },
  // pqrLicensingAuthority: {
  //   name: "pqrLicensingAuthority",
  //   component: "filterSelect",
  //   label: "Licensure Region",
  //   queryKey: "pqr_issue_authority_id",
  //   groupFilterName: "Eligibility",
  //   dependsOn: [],
  //   secondLevelDependentClear: ['pqrLevel'],
  //   secondLevelDependentDisable: ["pqrCountries"],
  //   selectDependentDisable: ["candidateLevel"],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false
  // },
  pqrHeader: {
    name: "pqrHeader",
    component: "filterSelect",
    label: "Licensure Region",
    queryKey: "pqr_header_id",
    groupFilterName: "Eligibility",
    dependsOn: [],
    secondLevelDependentClear: ['workingLevel'],
    secondLevelDependentDisable: ["pqrCountries"],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    autoPopulate: true,
  },
  // pqrLevel: {
  //   name: "pqrLevel",
  //   component: "filterSelect",
  //   label: "Licensing Level",
  //   queryKey: "pqr_level_id",
  //   groupFilterName: "Eligibility",
  //   dependsOn: ['candidateType'],
  //   secondLevelDependentDisable: ["pqrCountries"],
  //   selectDependentDisable: ["candidateLevel"],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false
  // },
  workingLevel: {
    name: "workingLevel",
    component: "filterSelect",
    label: "Level",
    queryKey: "pqr_working_level_id__in",
    groupFilterName: "Eligibility",
    dependsOn: ["candidateType"],
    secondLevelDependentDisable: ["pqrCountries"],
    selectDependentDisable: ["candidateLevel"],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    hideCondition: {
      conditionalField: "candidateType",
      value: [3],
      defaultShow: true,
    }
  },
  pqrAHPSpeciality: {
    name: "pqrAHPSpeciality",
    component: "filterSelect",
    label: "Speciality",
    queryKey: "candidate_pqrs_view___pqr_ahp_speciality_id",
    groupFilterName: "Eligibility",
    dependsOn: [],
    secondLevelDependentClear: [],
    secondLevelDependentDisable: ["pqrCountries"],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    hideCondition: {
      conditionalField: "candidateType",
      value: [1, 2, 4, 6],
      defaultShow: false,
    }
  },
  candidateLevel: {
    name: "candidateLevel",
    component: "filterSelect",
    label: "Working As (Level)",
    queryKey: "level_id__in",
    dependsOn: ["candidateType"],
    secondLevelDependentDisable: ["candidateType"],
    selectDependentDisable: [
      "pqrCountries", 
      "pqrHeader",
      // "pqrLevel"
    ],
    taggable: false,
    multiple: true,
  },
  // specialitySearch: {
  //   name: "specialitySearch",
  //   component: "customSelectOption",
  //   label: "Speciality",
  //   queryKey: "speciality_references",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   secondLevelDependentDisable: ['candidateType'],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  //   filterDynamicSearchAction: "genericSpecialitySearch",
  //   dependentQuerys: ["candidateType"]
  // },
  specialitySearch: {
    name: "specialitySearch",
    component: "accordionSelect",
    label: "Speciality/Sub-Speciality",
    queryKey: "speciality_references",
    dependsOn: [],
    dependentFilter: [],
    secondLevelDependentDisable: ['candidateType'],
    taggable: false,
    multiple: true,
    clearable: false,
    filterDynamicSearchAction: "genericSpecialitySearch",
    dependentQuerys: ["candidateType"],
  },
  // speciality: {
  //   name: "speciality",
  //   component: "filterSelect",
  //   label: "Speciality",
  //   queryKey: "speciality_id__in",
  //   dependsOn: ["candidateType"],
  //   secondLevelDependentDisable: ["candidateType"],
  //   dependentFilter: ["subSpeciality"],
  //   taggable: false,
  //   multiple: true,
  // },
  // subSpeciality: {
  //   name: "subSpeciality",
  //   component: "filterSelect",
  //   label: "Sub Speciality",
  //   queryKey: "sub_speciality_id__in",
  //   dependsOn: ["candidateType"],
  //   secondLevelDependentDisable: ["speciality"],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  // },
  // retiredClinician: {
  //   name: "retiredClinician",
  //   component: "filterSelect",
  //   label: "Retired Clinician​​",
  //   queryKey: "retired_clinician__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  // volunteer: {
  //   name: "volunteer",
  //   component: "filterSelect",
  //   label: "Volunteer (Unpaid)",
  //   queryKey: "volunteer__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  licence: {
    name: "licence",
    component: "filterSelect",
    label: "Regional License",
    queryKey: "local_license__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  // active: {
  //   name: "active",
  //   component: "filterSelect",
  //   label: "Active",
  //   queryKey: "available_during_covid__in__and",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: true,
  // },
  // deleted: {
  //   name: "deleted",
  //   component: "filterSelect",
  //   label: "Deleted",
  //   queryKey: "deleted",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  // availabilityType: {
  //   name: "availabilityType",
  //   component: "filterSelect",
  //   label: "Availability (Full Time / Part Time) ",
  //   queryKey: "job_type",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  // contractType: {
  //   name: "contractType",
  //   component: "filterSelect",
  //   label: "Contract",
  //   queryKey: "job_type",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: false,
  //   clearable: false,
  // },
  availabilityType: {
    name: "availabilityType",
    component: "filterSelect",
    label: "Contract",
    queryKey: "job_type",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  qualificationClassificationCountries: {
    name: "qualificationClassificationCountries",
    component: "filterSelect",
    label: "Qualification Country",
    queryKey: "qualification_country_id",
    groupFilterName: "Qualification Level",
    dependsOn: [],
    secondLevelDependentDisable: ["candidateType"],
    dependentFilter: [],
    apiDependentFilter: ["qualificationLevel"], //pqrLicensingAuthority is dependent on pqrCountries
    taggable: false,
    multiple: false,
  },
  qualificationLevel: {
    name: "qualificationLevel",
    component: "filterSelect",
    label: "Qualification Level",
    queryKey: "qualification_level_id",
    groupFilterName: "Qualification Level",
    dependsOn: [],
    secondLevelDependentDisable: ["qualificationClassificationCountries"],
    dependentFilter: [],
    taggable: false,
    multiple: false,
  },
  experience: {
    name: "experience",
    component: "filterDateInput",
    label: "Year Awarded",
    queryKey: "year_awarded",
    inputFields: ["year"],
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  eligible: {
    name: "eligible",
    component: "filterSelect",
    label: "Traffic Lights",
    queryKey: "candidate_eligibility",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  },
  currentCountry: {
    name: "currentCountry",
    component: "filterSelect",
    label: "Current Home Location",
    queryKey: "country_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  // category: {
  //   name: "category",
  //   component: "filterSelect",
  //   label: "Category",
  //   queryKey: "category__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  // preferredLocations: {
  //   name: "preferredLocations",
  //   component: "filterSelect",
  //   label: "Preferred Location",
  //   queryKey: "preferred_location_id__in",
  //   dependsOn: [],
  //   dependentFilter: [],
  //   taggable: false,
  //   multiple: true,
  //   clearable: false,
  // },
  gender: {
    name: "gender",
    component: "filterSelect",
    label: "Gender",
    queryKey: "gender_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  birthPlaces: {
    name: "birthPlaces",
    component: "filterSelect",
    label: "Birth Place",
    queryKey: "birth_place_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  nationalityInfo: {
    name: "nationalityInfo",
    component: "filterSelect",
    label: "Nationality",
    queryKey: "nationality_ids__overlap",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  qualificationCountries: {
    name: "qualificationCountries",
    component: "filterSelect",
    label: "Qualification Country",
    queryKey: "candidate_qualifications___main=true&candidate_qualifications___country_id__in",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: false,
  },
  locationRegion: {
    name: "locationRegion",
    component: "filterCheckBox",
    label: "Preferred Region",
    queryKey: "region_ids__overlap",
    dependsOn: [],
    groupFilterName: "Preferred Region/Country",
    dependentFilter: ["preferredLocations"],
    apiDependentFilter: ["preferredLocations"], 
    taggable: false,
    multiple: true,
    clearable: true,
    showCount: true,
  },
  preferredLocations: {
    name: "preferredLocations",
    component: "filterCheckBox",
    label: "Preferred Country",
    queryKey: "preferred_country_ids__overlap",
    dependsOn: ["locationRegion"],
    clearOnlyDepends: true,
    groupFilterName: "Preferred Region/Country",
    dependentFilter: [],
    secondLevelDependentDisable: ["locationRegion"],
    taggable: false,
    multiple: true,
    clearable: true,
    showCount: true,
  },
  locationLanguage: {
    name: "locationLanguage",
    component: "filterSelect",
    label: "Language",
    queryKey: "language_ids__overlap",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: true,
    clearable: false,
  },
  ageLimit: {
    name: "ageLimit",
    component: "filterRange",
    label: "Age limit",
    queryKey: "age__lte",
    dependsOn: [],
    dependentFilter: [],
    selected: [20], //default value
    min: 20,
    max: 70,
    type: "range",
  },
  isStudent: {
    name: "isStudent",
    component: "filterSelect",
    label: "Professional Type",
    queryKey: "is_student",
    dependsOn: [],
    dependentFilter: [],
    taggable: false,
    multiple: false,
    clearable: true,
  },
};
